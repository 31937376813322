import { getEnvProp } from '../../utils/helper';
var os = require('os');
var hostname = os.hostname();
var appEnv;
var getHostEnvironment = function () {
    var hostEnv = 'dev';
    if (hostname.indexOf('www.bjs.com') > -1 || hostname.indexOf('preprod.bjs.com') > -1) {
        hostEnv = 'production';
    }
    return hostEnv;
};
export var getKiboScript = function (hostName) {
    var script;
    // @ts-ignore
    if (hostName === 'www.bjs.com' || hostName === 'bjs.com' || hostName === 'preprod.bjs.com') {
        script = "<script type=\"text/javascript\">\n    var monetateT = new Date().getTime();\n    (function() {\n        var p = document.location.protocol;\n        if (p == \"http:\" || p == \"https:\") {\n            var m = document.createElement('script'); m.type = 'text/javascript'; m.async = true; m.src = \"//se.monetate.net/js/2/a-cdd6b983/p/bjs.com/custom.js\";\n            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(m, s);\n        }\n    })();\n    </script>";
    }
    else {
        script = "<script type=\"text/javascript\">\n    var monetateT = new Date().getTime();\n    (function() {\n        var p = document.location.protocol;\n        if (p == \"http:\" || p == \"https:\") {\n            var m = document.createElement('script'); m.type = 'text/javascript'; m.async = true; m.src = \"//se.monetate.net/js/2/a-cdd6b983/d/qa-1.bjs.com/custom.js\";\n            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(m, s);\n        }\n    })();\n    </script>";
    }
    return script;
};
var PIQHost = function () {
    appEnv = getHostEnvironment();
    if (appEnv === 'production') {
        return 'https://bjs.ms.tagdelivery.com/request';
    }
    else {
        return 'https://bjs-sandbox.ms.tagdelivery.com/request';
    }
};
var piqConversion = function () {
    appEnv = getHostEnvironment();
    if (appEnv === 'production') {
        return 'https://ts-apim-bjs-prod.azure-api.net/conversion';
    }
    else {
        return 'https://ts-apim-bjs-sandbox.azure-api.net/conversion';
    }
};
var piqAudienceCall = function () {
    appEnv = getHostEnvironment();
    if (appEnv === 'production') {
        return 'https://ts-func-ag-api-prod.azurewebsites.net/api/bjs/';
    }
    else {
        return 'https://ts-func-ag-api-sandbox.azurewebsites.net/api/bjs/';
    }
};
var bnplScriptSrc = function () {
    if (process.env.APP_ENV === 'production') {
        return 'https://toolbox.jifiti.com/Widgets/1.0.3/jifiti-widget.min.js';
    }
    else {
        return 'https://toolbox-uat.jifiti.com/Widgets/1.0.3/jifiti-widget.min.js';
    }
};
var bnplScriptKey = function () {
    if (process.env.APP_ENV === 'production') {
        return '7ba93ff49d0a4afbbff8f2275d266096';
    }
    else {
        return 'e59aebea3dfc4f9fbcb083986e84d6e6';
    }
};
var loginHeaders = function () {
    if (process.env.APP_ENV === 'production') {
        return {
            headers: {
                'Content-Type': 'application/json',
                'x-ibm-client-id': '54b73032-9147-44f6-97f5-e1573b6e199d'
            }
        };
    }
    else {
        return {
            headers: {
                'Content-Type': 'application/json',
                'x-ibm-client-id': '54b73032-9147-44f6-97f5-e1573b6e199d'
            }
        };
    }
};
export var environmentConst = {
    WEBSITE_BASE_URL: getEnvProp('WEBSITE_BASE_URL'),
    API_BASE_URL: getEnvProp('API_BASE_URL'),
    //SEARCH
    TRENDING_BASE_URL: getEnvProp('TRENDING_BASE_URL'),
    SEARCH_BASE_URL: getEnvProp('SEARCH_BASE_URL'),
    TRENDING_AUTHORIZATION: getEnvProp('TRENDING_AUTHORIZATION'),
    TRENDING_WINDOW: getEnvProp('TRENDING_WINDOW'),
    SEARCH_CONFIG: {
        navigationItems: 3,
        productItems: 0,
        searchItems: 5,
        matchPrefix: true,
        popularSearch: true,
        collection: getEnvProp('SEARCH_CONFIG_COLLECTION'),
        area: getEnvProp('SEARCH_CONFIG_AREA')
    },
    SEARCH_PRODUCT_CONFIG: {
        pageSize: 4,
        area: getEnvProp('SEARCH_CONFIG_AREA'),
        fields: ['*'],
        collection: getEnvProp('SEARCH_CONFIG_COLLECTION'),
        skip: 0,
        pruneRefinements: false,
        biasing: {
            biases: []
        }
    },
    WC_PICKUP_STORE: getEnvProp('WC_PICKUP_STORE'),
    //CART
    CART_INFO_COOKIE: getEnvProp('CART_INFO_COOKIE'),
    CART_INFO_COOKIE_DOMAIN: getEnvProp('CART_INFO_COOKIE_DOMAIN'),
    //USER
    USER_NAME_COOKIE: getEnvProp('USER_NAME_COOKIE'),
    USER_SIGNIN_RESPONSE_COOKIE: getEnvProp('USER_SIGNIN_RESPONSE_COOKIE'),
    USER_LOGGEDIN_COOKIE: getEnvProp('USER_LOGGEDIN_COOKIE'),
    PERSONALIZATIONID_LIVE: getEnvProp('PERSONALIZATIONID_LIVE'),
    //CLUB
    CLUB_DETAILS_STORAGE_KEY: getEnvProp('CLUB_DETAILS_STORAGE_KEY'),
    CLUB_NAME_COOKIE: getEnvProp('CLUB_NAME_COOKIE'),
    CLUB_PICKUP_STORE_COOKIE: getEnvProp('CLUB_PICKUP_STORE_COOKIE'),
    CLUB_ZIP_CODE_COOKIE: getEnvProp('CLUB_ZIP_CODE_COOKIE'),
    GOOGLE_MAPS_API_KEY: getEnvProp('GOOGLE_MAPS_API_KEY'),
    PRODUCT_FINDING_METHOD_SESSION_COOKIE: getEnvProp('PRODUCT_FINDING_METHOD_SESSION_COOKIE'),
    PRODUCT_FINDING_SUB_METHOD_SESSION_COOKIE: getEnvProp('PRODUCT_FINDING_SUB_METHOD_SESSION_COOKIE'),
    //PRICE
    CURRENCY_CODE: '$',
    MERCH_CATEGORY_IDENTIFIER: '102020041',
    CLEARANCE_VALUE: 8,
    // REVIEW
    // REVIEW_JS_URL: getEnvProp('REVIEW_JS_URL'),
    REVIEW_JS_URL: 'https://ui.powerreviews.com/stable/4.1/ui.js',
    REVIEW_APIKEY: getEnvProp('REVIEW_APIKEY'),
    REVIEW_LOCALE: getEnvProp('REVIEW_LOCALE'),
    REVIEW_MERCHANT_GROUP_ID: getEnvProp('REVIEW_MERCHANT_GROUP_ID'),
    REVIEW_MERCHANT_ID: getEnvProp('REVIEW_MERCHANT_ID'),
    //Label-insight
    LABEL_INSIGHT_ICONS: 'https://app.labelinsight.com/retailer-icon-embed/embed.js',
    LABEL_INSIGHT_NUTRITION_FACTS: 'https://app.labelinsight.com/digital-nfp-embed/embed.js',
    //Flix Media
    FLIX_MEDIA_SRC: 'https://media.flixfacts.com/js/loader.js',
    FLIX_MEDIA_DISTRIBUTOR: '3922',
    FLIX_MEDIA_LANGUAGE: 'us',
    FLIX_MEDIA_IN_PAGE: 'flix-inpage',
    FLIX_MEDIA_CHARSET: 'utf-8',
    salsify: 'https://chatidcdn.com/chatbar/bjs/latest.js',
    BR_ENV_BASE_URL: getEnvProp('BR_ENV_BASE_URL'),
    BR_ENV_BASE_URL_NOT_FOUND: getEnvProp('BR_ENV_BASE_URL_NOT_FOUND'),
    //SellPoint
    SELLPOINT_SCRIPT_FUNCTION: {
        desktopDetails: {
            desktopScriptSrc: '//a.sellpoint.net/c/4665/sp.js',
            desktopOtherScriptSrc: '//a.sellpoint.net/w/4665/spworld.min.js',
            callFunctionDesktop: "(function (d,s,l,t,p) { t = d.createElement(s); t.type ='text/java'+s; t.async = true; t.src = l;p = d.getElementsByTagName(s)[0]; p.parentNode.insertBefore(t,p); })(document,'script','//a.sellpoint.net/c/4665/sp.js?cv='+Math.floor(Date.now()/86400000));"
        },
        mobileDetails: {
            mobileScriptSrc: '//a.sellpoint.net/c/10017/sp.js',
            mobileOtherScriptSrc: '//a.sellpoint.net/w/10017/spworld.min.js',
            callFunctionDesktop: "(function (d,s,l,t,p) { t = d.createElement(s); t.type ='text/java'+s; t.async = true; t.src = l;p = d.getElementsByTagName(s)[0]; p.parentNode.insertBefore(t,p); })(document,'script','//a.sellpoint.net/c/10017/sp.js?cv='+Math.floor(Date.now()/86400000));"
        },
        commonSrc: {
            partOne: "var SPWORLD = SPWORLD || []; SPWORLD.push('",
            partTwo: "');"
        }
        //cnet
    },
    CNET_SCRIPT_CONTENT: {
        cnetSrc: '//cdn.cs.1worldsync.com/jsc/h1ws.js',
        partOne: "var ccs_cc_args = ccs_cc_args || []; ccs_cc_args.push(['mf','",
        partTwo: "']); ccs_cc_args.push(['pn','",
        partThree: "']); ccs_cc_args.push(['upcean', '']); ccs_cc_args.push(['lang', 'en']); ccs_cc_args.push(['market', 'US']); (function () { var o = ccs_cc_args; o.push(['_SKey', 'd0e726d2']); o.push(['_ZoneId', 'e7f41f8895']); var sc = document.createElement('script'); sc.type = 'text/javascript'; sc.async = true; sc.src = ('https:' == document.location.protocol ? 'https://' : 'https://') + 'cdn.cs.1worldsync.com/jsc/h1ws.js'; var n = document.getElementsByTagName('script')[0]; n.parentNode.insertBefore(sc, n); })();"
    },
    SALSIFY: {
        clientId: 's-ce7650ca-e6f3-4f3e-b140-c372b6223115',
        enhancedContent: {
            idType: 'BJSID'
        },
        languageCode: 'en-US',
        staging: false,
        jsSrc: 'https://chatidcdn.com/chatbar/bjs/latest.js'
    },
    //PROMOTE IQ CONFIG
    PROMOTE_IQ_CONFIG: {
        PIQSLOT: {
            hpDesktopSlotId: 23125,
            hpMobileSlotId: 23130,
            pdpDesktopSlotId: 23035,
            pdpMobileSlotId: 23040
        },
        piqHost: PIQHost(),
        piqConversion: piqConversion(),
        piqAudience: piqAudienceCall(),
        PIQ_AUDIENCE_COOKIE: getEnvProp('PIQ_AUDIENCE_COOKIE'),
        deviceWidth: {
            large: 481,
            xlarge: 767
        }
    },
    //BNPL Configs
    BNPL_CONFIG: {
        bnplScriptSrc: bnplScriptSrc(),
        bnplScriptKey: bnplScriptKey()
    },
    //OTHER CONSTANTS
    UPDATED_ZIP: 'updatedZip',
    ZIP_COOKIE: 'zip',
    GLOBAL_FULFILLMENT: 'bjs_default_fulfillment',
    PDP_ZIP_NOT_SERVICEABLE: 'pdpZipNotServiceable',
    PLP_COOKIE: 'BJs_PLP_Test_group',
    B2C_MEMBER_SEGMENT: getEnvProp('B2C_MEMBER_SEGMENT'),
    ssoLoginHeaders: loginHeaders()
};
export var iconLinks = {
    FreepickupICO: 'https://bjs.scene7.com/is/image/bjs/ICONS_COLOR_RGB_CurbsidePickup',
    DeliveryICO: 'https://bjs.scene7.com/is/image/bjs/ICONS_COLOR_SameDayDelivery1',
    ShippingICO: 'https://bjs.scene7.com/is/image/bjs/ICONS_COLOR_Shipping1'
};
